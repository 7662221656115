<template>
  <div
    v-if="avatarUrl"
    class="avatar"
    :class="{ small }"
    :style="{ backgroundImage: `url(${avatarUrl})` }"
  ></div>
  <div v-else class="avatar" :class="{ small }">
    <i-pajamas-face-neutral />
  </div>
</template>
<script lang="ts" setup>
const props = defineProps<{
  user: {
    avatar_url?: string | null | undefined;
    avatarUrl?: string | null | undefined;
  };
  small?: boolean;
}>();

const avatarUrl = computed(
  () => props.user?.avatarUrl || props.user?.avatar_url
);
</script>
<style scoped>
.avatar {
  --size: calc(4 * var(--grid));
  width: var(--size);
  height: var(--size);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: calc(2 * var(--grid));
  box-shadow: 0px 2px 8px -2px var(--black);
  background-color: white;
  display: grid;
  place-items: center;
}

.avatar.small {
  --size: calc(2 * var(--grid));
}

.avatar svg {
  width: 75%;
  height: 75%;
}
</style>
